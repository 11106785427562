import { MODAL_TYPES } from '~~/config/constant'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'

export const useHeader = () => {
  const { $pinia } = useNuxtApp()
  const { $state } = useAppStore($pinia)
  const { openModalWithNavigate } = useModal()

  const currentUser = computed(() => {
    return $state.currentUser
  })

  const onClickAuthor = (modalName = MODAL_TYPES.LOGIN, url = 'login') => {
    openModalWithNavigate(modalName, url)
  }

  return { onClickAuthor, MODAL_TYPES, currentUser }
}
